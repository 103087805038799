/*------------- Variable ------------*/
:root {
  --iq-title-text: #3f414d;
  --iq-body-text: #777d74;
  --iq-primary: #3f9b4b;
  --iq-primary-hover: #3f9b4b;
  --iq-secondary: #a09e9e;
  --iq-secondary-hover: #8f8f8f;
  --iq-success: #15c3a5;
  --iq-success-hover: #10b59a;
  --iq-warning: #f5a13d;
  --iq-warning-hover: #e6922b;
  --iq-danger: #ff6247;
  --iq-danger-hover: #bb2d3b;
  --iq-info: #50b5ff;
  --iq-info-hover: #38a2ee;
  --iq-dark: #3f414d;
  --iq-light: #fafafb;
  --iq-white: #ffffff;
  --iq-black: #000000;
  --iq-grey: #e4e6eb;
  --iq-light-primary: #d7ffe0;
  --iq-light-secondary: #f1f2f1;
  --iq-light-success: #d5f5f0;
  --iq-light-danger: #fff5f4;
  --iq-light-warning: #fff4d5;
  --iq-light-info: #eee6f5;
  --iq-light-dark: #f0efef;
  --iq-border-light: #f1f1f1;
  --iq-dark-primary: #29324f;
  --iq-dark-secondary: rgba(160, 158, 158, 0.2);
  --iq-dark-success: rgba(73, 240, 211, 0.2);
  --iq-dark-danger: rgba(255, 155, 138, 0.2);
  --iq-dark-warning: rgba(255, 186, 104, 0.2);
  --iq-dark-info: rgba(213, 146, 255, 0.2);
  --iq-border-light: #f1f1f1;
  --iq-dark-bg: #182039;
  --iq-dark-box: #1e2745;
  --iq-dark-body-text: #8c91b6;
  --iq-dark-title-text: #8c91b6;
  --iq-border-dark: #252e4b;
  --iq-yellow: #fdc800;
  --iq-green: #61ba6d;
  --iq-gradien: linear-gradient(to right, #61ba6d, #83c331);
  --iq-success-hover: #0ca98e;
}
